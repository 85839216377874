
// Body
$body-bg: #fff;

// Borders
$laravel-border-color: darken($body-bg, 10%);
$list-group-border: $laravel-border-color;
$navbar-default-border: $laravel-border-color;
$panel-default-border: $laravel-border-color;
$panel-inner-border: $laravel-border-color;

// Brands
$brand-primary: #3097D1;
$brand-info: #8eb4cb;
$brand-success: #2ab27b;
$brand-warning: #cbb956;
$brand-danger:  #bf5329;

// Typography
$font-family-sans-serif: "Raleway", sans-serif;
$font-family-handwriting: "Great Vibes", cursive;
$font-size-base: 14px;
$line-height-base: 1.6;
$text-color: #313131;

// Navbar
$navbar-default-bg: #fff;

// Buttons
$btn-default-color: $text-color;

// Inputs
$input-border: lighten($text-color, 40%);
$input-border-focus: lighten($brand-primary, 25%);
$input-color-placeholder: lighten($text-color, 30%);

// Panels
$panel-default-heading-bg: #fff;


// breakpoints

$mobile: new-breakpoint(min-width 320px);
$mobile-large: new-breakpoint(min-width 481px);
$tablet: new-breakpoint(min-width 768px);
$tablet-large: new-breakpoint(min-width 961px);
$bootstrap-breakpoint: new-breakpoint(min-width 992px);
$desktop: new-breakpoint(min-width 1024px);
$desktop-large: new-breakpoint(min-width 1281px);


//colours

$white: #fff;
$black: #000;

// project color
$tuinzorg-dark-blue: #34828F;
$tuinzorg-light-blue: #87B0C4;

$tuinzorg-light-green: #E5EA98;
$tuinzorg-green: #E7EA71;
$tuinzorg-dark-green: #C3D728;
$tuinzorg-extra-dark-green: #636d11;


$tuinzorg-light-brown: #EFE9CF;
$tuinzorg-brown: #6B443A;
$tuinzorg-dark-brown: #523527;

$tuinzorg-grey: #313131;

