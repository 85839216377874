// Bourbon
@import "vendors/bourbon/bourbon";

// Neat
@import "vendors/neat/neat";


// Font Awesome scss
@import "vendors/fontawesome/scss/font-awesome";

// Slick
@import "vendors/slick/slick";

// Slick Theme
@import "vendors/slick/slick-theme";

// Fonts
@import url('https://fonts.googleapis.com/css?family=Raleway');
@import url('https://fonts.googleapis.com/css?family=Great+Vibes');

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

// Font Awesome
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

// Variables
@import "variables";

// Partials
@import "partials/navigation";

// Pages
@import "pages/homepage";

