body {
	padding-top: 500px;
	font-family: $font-family-sans-serif;
}

.body-padding {
	padding-top: 550px;
}
@include media($tablet) {
	.body-padding {
		padding-top: 570px;
	}
}

header {
	background: $tuinzorg-light-brown;
	height: 500px;
	position: fixed;
	top: 0;
	width: 100%;
	
	&.header-visible {
		display: none;
	}
	
	h1 {
		margin-top: 40px;
	}
	
	
	#header-wrapper .carousel-wrapper,
	#header-carousel {
		height: 500px;
		width: 100%;
		position: absolute;
	}

	#header-carousel{
		img {
			left: 0;
	    	bottom: 0;
	    	min-width: 100%;
	    	min-height: 100%;
	    	max-width: none;
	    	width: auto;
	    	height: auto;
		}
		& i {
	    	position: absolute;
	    	top: 50%;
		}
	}

	.carousel-fade {
		.carousel-inner {
			.item {
				opacity: 0;
				transition-property: opacity;
				a {
					display: block;
					height: 500px;
					left: 0;
			    	bottom: 0;
			    	min-width: 100%;
			    	width: auto;
			    	background-position: center center;
  					background-repeat: no-repeat;
					  -webkit-background-size: cover;
					     -moz-background-size: cover;
					       -o-background-size: cover;
					          background-size: cover;
				}
			}

    		.active {
				opacity: 1;
			}

			.active.left,
			.active.right {
				left: 0;
				opacity: 0;
				z-index: 1;
			}

			.next.left,
			.prev.right {
				opacity: 1;
			}
		}

		.carousel-control {
			z-index: 2;
		}
	}
	
	.item.active {
		position: relative;
	}
	.item.active > span {
		position: absolute;
		display: table;
		margin: auto;
		text-align: center;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		color: $black;
		font-size: 36px;
		font-family: $font-family-sans-serif;
		font-weight: 100;
		width: 100%;
		visibility: visible;
		opacity: 1;
	}
	.item.active > span > span {
		position: absolute;
		display: block;
		left: -1px;
		top: -1px;
		color: $white;
		font-size: 36px;
		font-family: $font-family-sans-serif;
		font-weight: 100;
		width: 100%;
		visibility: visible;
		opacity: 1;
	}
	@include media($mobile-large) {
		.item.active > span {
			font-size: 48px;
		}
		.item.active > span > span {
			left: -2px;
			top: -2px;
			font-size: 48px;
		}
	}
	.item > span {
		visibility: hidden;
		opacity: 0;
		transition: visibility 0s, opacity 0.5s linear;
	}
	.item > span > span {
		visibility: hidden;
		opacity: 0;
		transition: visibility 0s, opacity 0.5s linear;
	}
}

.main-content {
	position: relative;
	background: $white;
	z-index: 20;
	
	#about {
		width: 100%;
		border-top: 1px solid $tuinzorg-grey;
		
		.about-container {
			padding-top: 20px;
			padding-bottom: 20px;
			
			.about-content {
				@include outer-container;
				position: relative;
				font-size: 13px;
				
				h1 {
				    border-bottom: 1px solid rgba(0,0,0,0.35);
				    margin: 0px;
				    padding-bottom: 15px;
				    margin-bottom: 8px;
    				padding-left: 10px;
				    font-family: $font-family-sans-serif;
				    font-weight: 100;
    				font-size: 28px;
				}
				h2 {
				    position: absolute;
    				top: 0px;
    				right: 0px;
    				font-family: $font-family-sans-serif;
				    font-weight: 100;
					margin: 0;
					margin-top: 7px;
					padding-right: 15px;
					font-size: 24px;
					display: none;
				}
				.about-text {
					position: relative;
					padding: 10px;
				}
				@include media($tablet) {
					h1 {
						font-size: 36px;
						padding-bottom: 15px;
					    margin-bottom: 10px;
	    				padding-left: 15px;
					}
					h2 {
						display: block;
					}
					.about-text {
						position: relative;
						padding: 15px;
					}
				}
			}
			@include media($desktop) {
				.about-content {
					font-size: 14px;
				}
			}
		}
		
	}
	
	
	#services {
		width: 100%;
		
		&.services__padding{
			padding-top: 50px;
		}
		@include media($tablet) {
			&.services__padding{
				padding-top: 70px;
			}
		}
		.services-nav {
			background-color: $tuinzorg-grey;
			width: 100%;
			height: 50px;
			position: relative;
			z-index: 999;
			
			&__fixed {
				position: fixed;
				top: 50px;
			}
			@include media($tablet) {
				&__fixed {
					top: 70px;
				}
			}
			
			&__container {
				text-align:center;
				padding: 0;
				position: relative;
				
				ul {
				    list-style: none;
				    padding: 0;
				    position: relative;
				}
				
				ul > li {
					display: inline-block; 
					margin-top: 7px;
					position: relative;
					padding-left: 13px;
					padding-right: 13px;
				}
				
				ul > li > a {
					display: block;
				    position: absolute;
				    height: 50px;
				    width: 100%;
				    margin-top: -7px;
				    margin-left: -13px;
				}
				
				ul > li > a:hover {
					background-color: rgba(255,255,255,0.1);
				}
			}
			@include media($bootstrap-breakpoint) {
				&__container {
				    min-width: 985px;
				}
			}
			&__icon {
				height: 36px;
				border: 0px;
				margin: 0;
				padding: 0;
			}
			&__title-container {
				display: none !important;
			}
			&__text {
				display: none;
			}
			&__title {
				display: none;
			}
		}
		@include media($mobile-large) {
			.services-nav {
				&__title-container {
					display: inline-block !important;
				    margin-top: 10px;
			    	float: left;
			    	padding-left: 10px;
			    }
				&__title {
					display: inline;
					color: $white;
					margin-right: 7px;
					font-family: $font-family-sans-serif;
					font-weight: 100;
					font-size: 28px;
				}
			}
		}
		@include media($tablet) {
			.services-nav {
				height: 70px;
				&__container {
					ul > li {
						margin-top: 10px;
						padding-left: 20px;
						padding-right: 20px;
					}
					ul > li > a {
					    height: 70px;
					    margin-top: -10px;
					    margin-left: -20px;
					}
				}
				
				&__icon {
					height: 50px;
				}
				&__title-container {
				    margin-top: 15px;
			    }
				&__title {
					font-size: 32px;
				}
			}
		}
		@include media($desktop) {
			.services-nav {
				&__text {
					display: inline;
					text-transform: uppercase;
					color: $white;
					margin-right: 7px;
				}
				
				&__title-container {
				    margin-top: 16px;
			    }
				&__title {
					font-size: 32px;
				}
			}
		}
		
		.services-content {
			@include outer-container;
			display:flex; 
			flex-direction: column; 
			padding: 0;
			
			
			&__even {
				background-color: $tuinzorg-light-brown;
				border-bottom: 0px;
			}
			
			&__odd {
				border-bottom: 0px;
			}
			
			&__icon {
				@include span-columns(12);
				order: 1;
				padding: 10px;
				padding-top: 20px;
				position: relative;
				
				> a {
					position: absolute;
					left: 0px;
					top: 60px;
				}
				
				&__img-left {
					width: 100%;
				}
				&__img-right {
					width: 100%;
				}
				
				> h2 {
					position: absolute;
					left: 10px;
					top: 30px;
					display: block;
					padding: 10px;
					padding-left: 20px;
					padding-right: 30px;
					background-color: rgba(255,255,255,0.80);
					border-bottom: 1px solid rgba(0,0,0,0.35);
					font-family: $font-family-sans-serif;
				    font-weight: 100;
				}
			}
				
			&__text {
				@include span-columns(12);
				order: 2;
				padding: 15px;
				padding-top: 0px;
				border-bottom: 1px solid rgba(0,0,0,0.35);
				font-size: 13px;
				
				h2 {
					display: none;
				}
			}
		}
		@include media($tablet) {
			.services-content {
				@include outer-container;
				display:block; 
				padding: 10px;
				
				&__even {
					border-bottom: 1px solid rgba(0,0,0,0.35);
				}
				
				&__odd {
					border-bottom: 1px solid rgba(0,0,0,0.35);
				}
				
				&__icon {
					@include span-columns(5);
					float: left;
					padding: 0px;
					
					> a {
						top: 10px;
					}
					&__img-left {
						width: 100%;
						padding: 0px;
					    padding-top: 52px;
					    padding-bottom: 10px;
					}
					&__img-right {
						width: 100%;
						padding: 0px;
					    padding-top: 52px;
					    padding-bottom: 10px;
					}
					h2 {
						display: none;
					}
				}
				
				&__text {
					@include span-columns(7);
					float: left;
					padding: 0px;
					border: 0px;
					
					h2 {
						display: block;
						margin: 0;
						margin-bottom: 5px;
						padding: 0;
						padding-top: 20px;
						padding-bottom: 10px;
						border-bottom: 1px solid rgba(0,0,0,0.35);
						font-family: $font-family-sans-serif;
					    font-weight: 100;
					    font-size: 20px;
					}

				}
			}
		}
		@include media($tablet-large) {
			.services-content {
				&__icon {
					@include span-columns(5);
					
					> a {
						top: 60px;
					}
					
					&__img-left {
						padding-top: 10px;
					}
					
					&__img-right {
						padding-top: 10px;
					}
				}
				&__text {
					@include span-columns(7);
				}
			}
		}
		@include media($desktop) {
			.services-content {
				&__icon {
					@include span-columns(4);
					
					&__img-left {
					    padding-right: 10px;
					}
					
					&__img-right {
					    padding-left: 10px;
					}
				}
				&__text {
					@include span-columns(8);
					font-size: 14px;
					h2 {
						font-size: 30px;
						margin: 0;
						margin-bottom: 5px;
						padding-top: 20px;
						padding-bottom: 10px;
					}
				}
			}
		}
		@include media($desktop-large) {
			.services-content {
				padding: 0;
				padding-top: 10px;
				padding-bottom: 10px;
			}
		}
		
	}

	
	#projects {
		width: 100%;
		background-color: $tuinzorg-dark-blue;
		
		.projects-container {
			    padding-top: 70px;
			    border-top: 90px solid $white;
			    margin-top: 60px;
			
			.projects-content {
				@include outer-container;
				position: relative;
				
				> h1 {
				    margin: 0px;
				    padding-bottom: 15px;
    				padding-left: 20px;
				    font-family: $font-family-sans-serif;
				    font-weight: 100;
    				font-size: 36px;
    				position: absolute;
    				color: $white;
    				left: 0;
    				top: -52px;
				}
				
				.projects__slider__slide__container {
					> h3 {
						display: block;
						margin: 0;
						padding: 10px;
						padding-left: 40px;
						padding-right: 30px;
						background-color: rgba(255,255,255,0.80);
						font-family: $font-family-sans-serif;
					    font-weight: 100;
	    				font-size: 36px;
	    				position: absolute;
	    				color: $black;
	    				left: 10px;
	    				top: 50px;
	    				z-index: 777;
	    				
	    				display: none;
					}
					> p {
						display: block;
						margin: 0;
						padding: 10px;
						padding-left: 40px;
						padding-right: 30px;
						background-color: rgba(255,255,255,0.80);
						font-family: $font-family-sans-serif;
					    font-weight: 100;
	    				font-size: 14px;
	    				position: absolute;
	    				color: $black;
	    				left: 10px;
	    				bottom: 60px;
	    				max-width: 70%;
	    				z-index: 777;
	    				
	    				display: none;
					}
					> img {
						margin: auto;
						max-height: 100%;
						max-width: 100%;
					}
				}
				
				.projects__slider__slide__container__image {
					height: 500px;
					background-repeat:no-repeat;
					background-position: center center;
					background-color: #f5f5ef!important;
					border: 1px solid black;
				}
				
				.projects__slider__slide__thumb__container__thumb {
					height: 250px;
					background-repeat:no-repeat;
					background-position: center center;
					background-size: cover;
				}
				
				@include media($tablet) {
					.projects__slider__slide__thumb__container__thumb {
						height: 175px;
						background-repeat:no-repeat;
						background-position: center center;
						background-size: cover;
					}
				}
				
				.slider-for {
					display: none;
					.projects__slider__slide {
				 		&__container {
				 			margin: 10px 10px;
				 			background-color: $white;
				 		}
				 	}
				}
				
				.slider-nav {
				 	.projects__slider__slide__thumb {
				 		&__container {
				 			margin: 10px;
				 			background-color: $white;
				 		}
				 	}
				 	.slick-center > .projects__slider__slide__container {
						color: #e67e22;
						opacity: 1;
						margin: 10px 10px;
						transform: scale(1.08);
					}
					
				}
				.slider-nav-thumb {
					background-color: $white;
					padding-bottom: 10px;
					margin-left: 10px;
					margin-right: 10px;
					
					
					.projects__slider__slide__thumb__container {
						border: 1px solid $black;
						margin:	10px 10px 0px 11px;
						
						> h3 {
							display: block;
							padding: 7px;
							padding-left: 10px;
							background-color: rgba(255,255,255,0.70);
							font-family: $font-family-sans-serif;
						    font-weight: 100;
		    				font-size: 16px;
		    				color: $black;
		    				left: 0px;
		    				bottom: 15px;
		    				z-index: 777;
		    				height: 100%;
		    				border: 1px solid $black;
		    				
		    				display: none;
						}
					}
					.slick-center > .projects__slider__slide__thumb__container {
						color: #e67e22;
						opacity: 1;
						margin:	10px 10px 0px 11px;
    					border: 1px solid $black;
						
						> h3 {
							display: block;
							height: auto;
							padding: 7px;
							padding-left: 10px;
							background-color: rgba(255,255,255,0.90);
							font-family: $font-family-sans-serif;
						    font-weight: 100;
		    				font-size: 16px;
		    				color: $black;
		    				z-index: 777;
		    				border: 0;
		    				margin: 0;
		    				display: none;
		    				
						}
					}
				}
			}
		}
		
		@include media($tablet) {
			.projects-container {
				padding-top: 70px;
				border-top: 70px solid $white;
				
				.projects-content {
					position: relative;
					
					> h1 {
	    				top: -47px;
					}
					.slider-for {
					 	display: block;
					}
				}
			}
		}
		
	}
	@include media($tablet) {
		#projects {
			margin-top: 71px;
		}
	}
	
	#contact {
		width: 100%;
		
		.contact-container {
			padding-top: 15px;
			border-top: 90px solid $white;
			margin-top: 60px;
			background-color: $tuinzorg-light-green;
			
			.contact-content {
				@include outer-container;
				display: block;
				
				.contact-form {
					@include span-columns(12);
					padding-bottom: 25px;
					
					.styled-form {
						@include row();
						
						h1 {
						    border-bottom: 1px solid rgba(0,0,0,0.35);
						    margin: 0px;
						    padding-bottom: 15px;
						    padding-top: 15px;
						    margin-bottom: 8px;
		    				padding-left: 10px;
						    font-family: $font-family-sans-serif;
						    font-weight: 100;
		    				font-size: 36px;
						}
						.note {
							padding-left: 10px;
							padding-right: 10px;
							font-size: 13px;
						}
						ul > li {
							color: red;
							font-size: 11px;
						}
						.row-half-first {
						    @include span-columns(12);
						    padding-left: 10px;
						    padding-right: 10px;
					    }
						.row-half {
						    @include span-columns(12);
						    margin-right: 0px;
						    padding-right: 10px;
						    padding-left: 10px;
					    }
					    .row-full {
						    @include span-columns(12);
						    padding-left: 10px;
						    padding-right: 10px;
						    button {
						    	border: 0px;
						        -webkit-box-sizing: border-box;
							    -moz-box-sizing: border-box;
							    box-sizing: border-box;
							    white-space: nowrap;
							    position: relative;
							    display: -moz-inline-stack;
							    display: inline-block;
							    vertical-align: middle;
							    zoom: 1;
							    font-family: "brandon-grotesque", Helvetica, Arial, sans-serif;
							    font-weight: 700;
							    letter-spacing: 2px;
							    font-size: 14px;
							    line-height: 14px;
							    text-transform: uppercase;
							    color: white;
							    background-color: $tuinzorg-brown;
							    cursor: pointer;
							    border-bottom: 5px solid $tuinzorg-dark-brown;
							    -webkit-border-radius: 4px;
							    -moz-border-radius: 4px;
							    -ms-border-radius: 4px;
							    -o-border-radius: 4px;
							    border-radius: 4px;
							    -webkit-transition: none 125ms ease-out;
							    -webkit-transition-delay: 0;
							    -moz-transition: none 125ms ease-out 0;
							    -o-transition: none 125ms ease-out 0;
							    transition: none 125ms ease-out 0;
							    -webkit-transition-property: background-color, color;
							    -moz-transition-property: background-color, color;
							    -o-transition-property: background-color, color;
							    transition-property: background-color, color;
							    padding: 10px 55px;
						    }
						    button:hover {
						    	background-color: $tuinzorg-dark-brown;
						        border-bottom: 5px solid $tuinzorg-brown;
    							text-decoration: none;
						    }
						    button:active {
							    border-bottom-width: 0;
							    margin-bottom: 5px;
							    top: 5px;
							}
							
							
					    }
					    label {
					        display: block;
						    font-family: $font-family-sans-serif;
							font-size: 14px;
						    line-height: 20px;
						    font-weight: bold;
						    text-transform: uppercase;
					    }
					    
					    .field {
						    display: block;
						}
						input[type=text], input[type=password], textarea {
						    outline: none;
						    width: 100%;
						    padding: 1rem 1rem;
						    margin: 0;
						    font-size: 1.4rem;
						    border-radius: 0;
						    border: 1px solid #8a979b;
						    border-radius: 0.3rem;
						    -webkit-transition: border 300ms cubic-bezier(0.63, 0.64, 0.3, 1), color 300ms cubic-bezier(0.63, 0.64, 0.3, 1);
						    transition: border 300ms cubic-bezier(0.63, 0.64, 0.3, 1), color 300ms cubic-bezier(0.63, 0.64, 0.3, 1);
						}
					}
				}
				.contact-info {
					@include span-columns(12);
					background-color: $tuinzorg-dark-green;
					margin-bottom: 10px;
					padding: 30px;
					
					dl {
						font-family: $font-family-sans-serif;
						font-weight: 100;
						font-size: 14px;
						
						dt {
							text-transform: uppercase;
						}
						dd {
							margin-bottom: 10px;
							
							a, a:visited {
								color: $black;
								text-decoration: none;
							}
							a:hover, a:active {
								color: $black;
								text-decoration: underline;
							}

						}
						.social-links {
							.fb, .tt, .li {
								display: block;
							    height: 50px;
							    width: 50px;
							    float: left;
							    margin: 0;
							    padding: 0;
							    margin-right: 10px;
							    border-radius: 6px;
							    overflow: hidden;
								
								span {
									display: block;
									height: 50px;
									width: 50px;
									background-repeat: no-repeat;
									background-size: cover;
								}
							}
							.fb span {
							    background-image: url('../images/icon_facebook_square_black.png');
							}
							.fb:hover span {
							    background-image: url('../images/icon_facebook_square.png');
							    background-color: $white;
							}
							.tt span {
							    background-image: url('../images/icon_twitter_square_black.png');
							}
							.tt:hover span {
							    background-image: url('../images/icon_twitter_square.png');
							    background-color: $white;
							}
							.li span {
							    background-image: url('../images/icon_linkedin_square_black.png');
							}
							.li:hover span {
							    background-image: url('../images/icon_linkedin_square.png');
							    background-color: $white;
							}
							
						}
					}
				}
			}
		}
		@include media($mobile-large) {
			.contact-container {
				.contact-content {
					.contact-form {
						
						.styled-form {
							.row-half-first {
							    @include span-columns(6);
							    padding-left: 10px;
							    padding-right: 0px;
						    }
							.row-half {
							    @include span-columns(6);
							    margin-right: 0px;
							    padding-right: 10px;
							    padding-left: 0px;
						    }
						}
					}
				}
			}
		}
		@include media($tablet) {
			.contact-container {
				padding-top: 70px;
				border-top: 70px solid $white;
				padding-bottom: 50px;
				
				.contact-content {
					display: flex;
					
					.contact-form {
						@include span-columns(8);
						padding-bottom: 0px;
						
						.styled-form {
							h1 {
								font-size: 36px;
							}
							.note {
								font-size: 14px;
								padding-right: 0px;
							}
							.row-half {
							    padding-right: 0px;
						    }
						    .row-full {
							    padding-right: 0px;
							    
							    &.submit {
							    	text-align: right;
							    }
							}
						}
					}
					.contact-info {
						@include span-columns(4);
						align-items: stretch;
					}
				}
			}
		}
		@include media($desktop) {
			.contact-container {
				
				.contact-content {
					
					.contact-form {
						@include span-columns(9);
					}
					.contact-info {
						@include span-columns(3);
					}
				}
			}
		}
	}
	
	#footer {
		width: 100%;
		background-color: $tuinzorg-grey;
		
		.footer-content {
			@include outer-container;
			padding-top: 5px;
			padding-bottom: 15px;
			padding-left: 10px;
			padding-right: 10px;
			color: $white;
			display:flex; 
			flex-direction: column;
			 
			&__column1 {
				@include span-columns(12);
				order: 3;
				h2 {
					border-bottom: 1px solid $white;
					margin: 0px;
					padding-bottom: 15px;
					padding-top: 15px;
					margin-bottom: 8px;
					font-family: $font-family-sans-serif;
					font-weight: 100;
		    		font-size: 18px;
				}
				p {
					font-size: 13px;
					a, a:visited {
						color: $white;
						text-decoration: none;
					}
					a:hover, a:active {
						color: $white;
						text-decoration: underline;
						}
				}
			}	
			&__column2 {
				@include span-columns(12);
				order: 1;
				h2 {
					border-bottom: 1px solid $white;
					margin: 0px;
					padding-bottom: 15px;
					padding-top: 15px;
					margin-bottom: 8px;
					font-family: $font-family-sans-serif;
					font-weight: 100;
		    		font-size: 18px;
				}
				p {
					font-size: 13px;
				}
				.nieuwsbrief {
					@include row();
					padding-top: 6px;
					
					#id_newsletter-email {
						@include span-columns(10);
						outline: none;
						padding: 10px 10px;
						margin: 0;
						font-size: 1.4rem;
						border-radius: 0;
						border: 0;
						border-top-left-radius: 4px;
					    border-top-right-radius: 0px;
					    border-bottom-right-radius: 0px;
					    border-bottom-left-radius: 4px;
						-webkit-transition: border 300ms cubic-bezier(0.63, 0.64, 0.3, 1), color 300ms cubic-bezier(0.63, 0.64, 0.3, 1);
						transition: border 300ms cubic-bezier(0.63, 0.64, 0.3, 1), color 300ms cubic-bezier(0.63, 0.64, 0.3, 1);
						color: $black;
					}
					.error-placeholder::-webkit-input-placeholder { /* WebKit, Blink, Edge */
					    color:    red;
					}
					.error-placeholder:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
					   color:    red;
					   opacity:  1;
					}
					.error-placeholder::-moz-placeholder { /* Mozilla Firefox 19+ */
					   color:    red;
					   opacity:  1;
					}
					.error-placeholder:-ms-input-placeholder { /* Internet Explorer 10-11 */
					   color:    red;
					}
					#id_newsletter-submit {
						@include span-columns(2);
						border: 0px;
						-webkit-box-sizing: border-box;
						-moz-box-sizing: border-box;
						box-sizing: border-box;
						white-space: nowrap;
						position: relative;
						display: -moz-inline-stack;
						display: inline-block;
						vertical-align: middle;
						zoom: 1;
						letter-spacing: 2px;
						font-size: 1.4rem;
						line-height: 14px;
						text-transform: uppercase;
						font-weight: bold;
						color: white;
						background-color: $tuinzorg-dark-green;
						cursor: pointer;
						border-bottom: 5px solid $tuinzorg-green;
						border-top-left-radius: 0px;
					    border-top-right-radius: 4px;
					    border-bottom-right-radius: 4px;
					    border-bottom-left-radius: 0px;
						-webkit-transition: none 125ms ease-out;
						-webkit-transition-delay: 0;
						-moz-transition: none 125ms ease-out 0;
						-o-transition: none 125ms ease-out 0;
						transition: none 125ms ease-out 0;
						-webkit-transition-property: background-color, color;
						-moz-transition-property: background-color, color;
						-o-transition-property: background-color, color;
						transition-property: background-color, color;
						padding: 10px 10px;
						padding-bottom: 11px;
						color: $black;
					}
					#id_newsletter-submit:hover {
						background-color: $tuinzorg-green;
						border-bottom: 5px solid $tuinzorg-dark-green;
    					text-decoration: none;
					}
					#id_newsletter-submit:active {
						border-bottom-width: 0;
						margin-bottom: 5px;
						top: 5px;
					}
				
				}
				
			}
			&__column3 {
				@include span-columns(12);
				order: 2;
				h2 {
					border-bottom: 1px solid $white;
					margin: 0px;
					padding-bottom: 15px;
					padding-top: 15px;
					margin-bottom: 8px;
					font-family: $font-family-sans-serif;
					font-weight: 100;
		    		font-size: 18px;
				}
				p {
					font-size: 13px;
				}
				
				.fb, .tt, .li {
					display: block;
					height: 40px;
					width: 40px;
					float: left;
					margin: 0;
					padding: 0;
					margin-right: 10px;
					border-radius: 4px;
					overflow: hidden;
					margin-top: 5px;
								
					span {
						display: block;
						height: 42px;
					    width: 42px;
					    margin-left: -1px;
					    margin-top: -1px;
						background-repeat: no-repeat;
						background-size: cover;
					}
				}
				.fb span {
					background-image: url('../images/icon_facebook_square_gray.png');
				}
				.fb:hover span {
					background-image: url('../images/icon_facebook_square.png');
					background-color: $white;
				}
				.tt span {
					background-image: url('../images/icon_twitter_square_gray.png');
				}
				.tt:hover span {
					background-image: url('../images/icon_twitter_square.png');
					background-color: $white;
				}
				.li span {
					background-image: url('../images/icon_linkedin_square_gray.png');
				}
				.li:hover span {
					background-image: url('../images/icon_linkedin_square.png');
					background-color: $white;
				}
			}
		}
		@include media($tablet) {
			.footer-content {
				display: block;
				padding-left: 0px;
				padding-right: 0px;
				&__column1 {
					@include span-columns(4);
				}
				&__column2 {
					@include span-columns(4);
				}
				&__column3 {
					@include span-columns(4);
				}
			}
		}
	}
}
.slick-dots li button {
	color: $white;
}

.slick-dots li button:before {
	color: $white;
	font-size: 14px;
}

