.navbar-default {
	background-color: rgba(255,255,255,0.75);
	border-color: rgba(255,255,255,0.5);
}

.navbar-inner {
    min-height: 0px;
}

.navbar-brand,
.navbar-nav li a {
    line-height: 50px;
    height: 50px;
    padding-top: 0;
}

a.navbar-brand {
	display: block;
	background-image: url("../images/logo-small.png");
	margin-top: 2px;
	margin-left: 3px;
	height: 44px;
	width: 179px;
	background-size: 179px 44px;
    background-repeat: no-repeat;
}

.collapse.in,
.navbar-default.open {
    background-color: rgba(255,255,255,1);
	border-color: rgba(255,255,255,1);
}

.navbar-collapse.in, .navbar-collapse.collapsing {
    overflow-y: visible;
}

@include media($tablet) {
    .navbar-brand,
	.navbar-nav li a {
    	line-height: 70px;
    	height: 70px;
    	padding-top: 0;
	}
	
	a.navbar-brand {
		background-image: url("../images/logo.png");
		margin-left: 0px;
		margin-top: 5px;
		height: 60px;
		width: 253px;
		background-size: 253px 60px;
    	background-repeat: no-repeat;
	}
	
	.collapse.in {
    	background-color: rgba(255,255,255,0);
		border-color: rgba(255,255,255,0);
	}
	.navbar-default.open {
    	background-color: rgba(255,255,255,0.75);
		border-color: rgba(255,255,255,0.5);
	}
}


.navbar.affix {
    position: fixed;
    top:0;
    width:100%;
    background-color: rgba(255,255,255,1);
    border-color: $tuinzorg-grey;
}

#app-navbar-collapse ul a {
	color: $black;
	font-weight: bold;
}

#app-navbar-collapse ul a:hover {
	border-bottom: 6px solid $tuinzorg-light-green;
}

#app-navbar-collapse ul .current a {
	border-bottom: 6px solid $tuinzorg-dark-green;
}

/* Important styles */
#toggle {
    position: relative;
    float: right;
	display: block;
	width: 28px;
	height: 30px;
	margin: 10px 20px 0px 0px;
	cursor: pointer;
}
@include media($tablet) {
    #toggle {
    	display: none;
	}
}

#toggle span:after,
#toggle span:before {
	content: "";
	position: absolute;
	left: 0;
	top: -9px;
}
#toggle span:after{
	top: 9px;
}
#toggle span {
	position: relative;
	display: block;
	margin-top: 13px;
}

#toggle span,
#toggle span:after,
#toggle span:before {
	width: 100%;
	height: 5px;
	background-color: $tuinzorg-grey;
	transition: all 0.3s;
	backface-visibility: hidden;
	border-radius: 2px;
}

/* on activation */
#toggle.on span {
	background-color: transparent;
}
#toggle.on span:before {
	transform: rotate(45deg) translate(5px, 5px);
}
#toggle.on span:after {
	transform: rotate(-45deg) translate(7px, -8px);
}

